import React from "react";
import { withTranslation } from "react-i18next";
import {
  Badge,
  Button,
  ButtonGroup,
} from "shards-react";

class UserListNew extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      users:[...this.props.users],
      filteredQuestions:[],
      filter:'Todas'
    }
  }

  componentDidMount() {
    this.fetchNewData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldUpdate !== this.props.shouldUpdate) {
      // alert('Can evaluate!')
      this.fetchNewData()
    }
  }

  fetchNewData() {
    const myUsers = this.props.users;
    console.log('NMy users')
    console.log(myUsers)
    this.setState({ users: myUsers });
    // const className = "User";
    // var ItemClass = Parse.Object.extend(className);
    // var query = new Parse.Query(ItemClass);
    // query.limit(1000000)
    // .doesNotExist("deletedAt")
    // .ascending("firstName");
    
    // query.find({ useMasterKey: true })
    // .then((results) => {
    //   const users = results.filter(user => user.get('role') !== 'murmuratto');
    //   this.setState({ users});
    //   this.massageData(results);
    // }, (error) => {
    //     alert('Hubo un error en la busca. Favor de tratar luego.');
    // });
  }


  massageData(results) {
    const modifiedData = results.map((idea) => {
      const newData = {
        "Last Name": idea.get("lastName"),
        "First Name": idea.get("firstName"),
        "Job Title": idea.get("jobTitle") && idea.get("jobTitle"),
        "Supervisor/Manager [Y/N]": (idea.get("isSupervisor")?'Y':'N'),
        "Department/Team Name": idea.get("department") && idea.get("department"),
        "User Name (E-mail)":idea.get("username") && idea.get("username"),
        "Gender (for statistical purposes)": idea.get("gender") && idea.get("gender"),
        "Year of Birth (for statistical purposes)": idea.get("dbo") && idea.get("dob"),
        "Self-Identified Minority": idea.get("departmentLeader") && idea.get("departmentLeader"),
        "Coach [Y/N]": (idea.get("coach")?'Y':'N'),
        "SuperUser [Up to 3]": idea.get("role") && (idea.get("role") == 'super_user'? 'Y':'N'),
        "PMO Committee [Y/N]": (idea.get("pmo")?'Y':'N'),
        "Idea Verification Access [Y/N]": idea.get("role") && (idea.get("role") == 'verification'? 'Y':'N'),
        "Reward & Recognition [Y/N]": idea.get("role") && (idea.get("role") == 'recognition'? 'Y':'N'),
        "Department Leader [Y/N]": idea.get("deparmentLeader") && (idea.get("departmentLeader")?'Y':'N'),
        "Department Name": idea.get("supervisedDepartment") && idea.get("supervisedDepartment"),
      }

      return(newData)
    })

    this.props.setDataCsv(modifiedData)
  }

  editItem(item) {
    this.props.editItem(item)
  }

  render() {
    const {users} = this.state;
    const { t } = this.props;
    const language = this.props.i18n.language;
    return(
      
            <table className="table mb-0">
              <thead className="py-2 bg-light text-semibold border-bottom">
                <tr>
                  <th className="text-center">{t("NAME")}</th>
                  <th className="text-center">{t("DEPARTMENT")}</th>
                  <th className="text-center">{t("USER ID")}</th>
                  <th className="text-center">{t("ROLE")}</th>
                  <th className="text-right">{"ACTION"}</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, idx) => (
                  <tr key={idx}>
                    <td className="lo-stats__order-details text-center">
                      {item.firstName + " " + item.lastName}
                    </td>
                    <td className="lo-stats__total text-center text-success">
                      {item.department ? item.department.title[language] : 'N/A'}
                    </td>
                    <td className="lo-stats__total text-center text-success">
                      {item.email}
                    </td>
                    <td className="lo-stats__items text-center">{item.roles.map((r) => {
                      return <Badge size="xs" theme="secondary" style={{marginRight:2}}>{r}</Badge>
                    })}</td>
                    <td className="lo-stats__actions">
                      <ButtonGroup className="d-table ml-auto">
                        <Button size="sm" theme="white" onClick={e => this.editItem(item)}>
                          Edit
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          
    )
  }
}

export default withTranslation()(UserListNew);
