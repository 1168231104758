import moment from 'moment';
import Parse from 'parse';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, Row } from "shards-react";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import { sendEmail } from '../../services/emailService';
import { getStartDate } from '../../services/ideaService';
import { changeTargetCompletionDate } from '../../services/implementService';
import { authUser } from "../../services/userService";
import colors from "../../utils/colors";
import IdeaAttachmentModal from '../common/IdeaAttachmentModal';


function OriginalIdeaInfo({open, currIdea, openIdea, changeShouldReload, canClick}) {
  const { t } = useTranslation();
  const [userName, setUsername] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(open)
  const [oldDate, setOldDate] = useState('')
  const [targetDate, setTargetDate] = useState('')
  const [teamMembers, setTeamMembers] = useState('')
  const [executionRes, setExecutionRes] = useState('')
  const [executionCoach, setExecutionCoach] = useState([])
  const [saveCoaches, setSaveCoaches] = useState([])
  const [isSuperUser, setIsSuperUser] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [isCoach, setIsCoach] = useState(false)
  const currentUser = authUser();
  
  const setInitialTitle = async (user) => {
  const completionDate = currIdea.targetDate

    if (completionDate) {
      setTargetDate(completionDate)
      setOldDate(completionDate)
    } else {
      setOldDate('N/A')
    }

  }

  useEffect(() => {
    const fetchStartDate = async () => {
      const storedFicha = sessionStorage.getItem(`idea_startDate_${currIdea.id}`);
      if (!storedFicha) {
        const startDateDb = await getStartDate(currIdea);
        sessionStorage.setItem(`idea_startDate_${currIdea.id}`, startDateDb);
        setStartDate(startDateDb);
      }else{
        setStartDate(storedFicha);
      }
    };

    fetchStartDate();
  }, []);

  useEffect(() => {
    setInitialTitle()
    const currentUser = authUser();
    const isSuperUser = currentUser.role === "super_user" || currentUser.role === "murmuratto"
    const isCoach = currentUser.coach == true
    setIsSuperUser(isSuperUser)
    setIsCoach(isCoach)
  }, []);

  const getTeamMembers  = () => {
    const teamMemberObjs = currIdea.teamMembers
      
    if (teamMemberObjs) {
      return currIdea.teamMembers.join(" • ");
    }else {
      return "N/A";
    }
  }


  const getIdeaType = () => {
    switch (currIdea.ideaType) {
      case "innovacion":
        return "Innovation"
      case "problema":
        return "Problem Solving"
      default:
        return "Continuous Improvement"
    }
  }

  const getCoach = () => {
    if (currIdea.coaches && currIdea.coaches.length > 0) {
      return currIdea.coaches.join(", ");
    } else {
      return "N/A";
    }
  }

  const getTargetDate = () => {
    const targetDate = currIdea.targetDate
    
    if (targetDate) {
      return moment(targetDate).format("DD MMMM YYYY"); 
    } else {
      return "N/A"
    }

  }

  const changeOwner = () => {
    const responsibleEmail = executionRes.value.get("email")
    const responsibleName = executionRes.value.get("firstName") + ' ' + executionRes.value.get("lastName")
    const responsible = executionRes.value
    const currentUser = Parse.User.current();

    currIdea.set("responsibleEmail", responsibleEmail)
    currIdea.set("responsibleName", responsibleName)
    currIdea.set("responsible", responsible)
    currIdea.set("customUpdatedAt", new Date());
    currIdea.save()
      .then(async (nextStep) => {
        logIdeaHistory(currIdea)
        if(responsible){
          await sendEmail({
            typeEmail: 'M_NE_01',
            client: process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo',
            subject: 'Assigned to you',
            userId: currentUser.id,
            ideaId: currIdea.id,
          })
        }
        // Execute any logic that should take place after the object is saved.
        // alert('Info saved.');
      }, (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        alert('Failed to create new object, with error code: ' + error.message);
      });
    }

  const changeTargetDate = async (newDeadline) => {
    setTargetDate(newDeadline)
    try {
      await changeTargetCompletionDate(currIdea.id, newDeadline)
    } catch (error) {
      alert(error.message)
    } 
    }

    const  logIdeaHistory = (idea) => {

      const ideaObjectJson = idea.toJSON();
      ideaObjectJson.ideaReference = ideaObjectJson.objectId
      delete ideaObjectJson.objectId;
      delete ideaObjectJson.date;
      delete ideaObjectJson.proponentObj;
      delete ideaObjectJson.responsible;
      delete ideaObjectJson.createdAt;
      delete ideaObjectJson.updatedAt;
      
      const IdeaHistoryItem = Parse.Object.extend("IdeaHistory");
      const newItem = new IdeaHistoryItem();
      
      newItem.set(ideaObjectJson)
      newItem.set("customUpdatedAt", new Date());
      newItem.save().then(() => {
        alert('Change completed. Please refresh the page to see the updated information.')
      }).catch((error) => {
        alert(error)
      })
    }
  
    const changeResponsible = (res, idx) => {
      setExecutionRes(res)
    }

    const changeCoach = (res, idx) => {
      setExecutionCoach(res)
      let setCoaches = null
      if(res){
        setCoaches = res.map(option => option.value)
      }
      
      setSaveCoaches(setCoaches)
    }

    const formatDate = (dateString) => {
     
      const date = new Date(dateString);
      const formatter = new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
     
      return formatter.format(date);
    };

  return (
    <Row>
      {/* Latest Orders */}
      <Col lg="12" className="m-auto">
              <div style={{width: '100%', borderStyle: 'solid',borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 5, display: 'flex'}}>
                <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
                    <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>{t("IdeaDetails") + " : #" +  + currIdea.num + " " + currIdea.title}</h6>
                </div>

                { categoryIsOpen && <ArrowUpIcon onClick={() => setCategoryIsOpen(false)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowUpIcon> }
                { !categoryIsOpen && <ArrowDownIcon onClick={() => setCategoryIsOpen(true)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowDownIcon>}
              
              </div>
              { categoryIsOpen &&
              <div className="mt-2"style={{backgroundColor: '#F6F6F6' }}>
                <Row form className="mx-4 mt-2">
                  <Col lg="12" className="mt-2">
                    <Row className="mt-4">
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('creator')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.proponent}</h6>
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('TITLE')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.title}</h6>
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('CATEGORY')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.category['name']}</h6>
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('TYPE')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{getIdeaType()}</h6>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('START_DATE')}</label>
                         
                              <h6 style={{fontWeight: 500,  color: '#303030'}}>
                                 {currIdea.startedAt ? formatDate(currIdea.startedAt) : 'N/A'}
                              </h6>
                            
                         
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('TARGET_COMPLETION_DATE')}</label>                         
                            {canClick == false?
                              <h6 style={{fontWeight: 500,  color: '#303030'}}>
                                 {currIdea.completionTargetDate ? formatDate(currIdea.completionTargetDate) : 'N/A'}
                              </h6>
                            :
                            <DatePicker
                            placeholderText={currIdea.completionTargetDate ? formatDate(currIdea.completionTargetDate) : null}
                            dropdownMode="select"
                            className="text-center"
                            onChange={(newDate) => changeTargetDate(newDate)} 
                            selected={targetDate ? targetDate : null}
                          /> 
                            }    
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('IMPLEMENTATION_LEADER')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.implementationLeader|| 'N/A'}</h6>
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">Coach</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{getCoach()}</h6>
                        </Col>
                    </Row>
  
                    {getTeamMembers() && 
                    <Row className="mt-4">
                        <Col md="12">
                          <label htmlFor="firstName" className="georgia">Team Members</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{getTeamMembers()}</h6>
                        </Col>
                    </Row>}
                    {currIdea.evaluation && 
                      <Row className="mt-4">
                        <Col md="12">
                          <label htmlFor="firstName" className="georgia">Evaluation Comments</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.evaluation.comment}</h6>
                          <p className="georgia">By {currIdea.evaluation.evaluatedBy} | {moment(currIdea.evaluation.evaluatedAt).format("DD MMMM YYYY")}</p>
                        </Col>
                    </Row>}
                    {currIdea.evaluation && currIdea.evaluation.attachment && (
                   <>
                    <Row className="mt-4">
                           <Col md="12">
                            <label htmlFor="firstName" className="georgia">Evaluation Attachment</label>
                            <Button onClick={() => setModalVisible(true)} style={{display: 'flex'}} >View Attachment</Button>  
                          </Col>
                    </Row>
                    <IdeaAttachmentModal ideaItem={currIdea.evaluation.attachment} onClick={setModalVisible} isVisible={modalVisible} type="evaluationFile"/>
                    </>
                    )}
                  </Col>
                       
                        
                    <Col lg="12" className="mt-2">
                        <Row className="mb-4 ml-auto">
                            <Col md="10">
                            </Col>
                            <Col md="2" className="ml-auto">
                              <Button className="ml-auto" pill style={{backgroundColor:"#6e4db8", borderColor: "#6e4db8"}} onClick={() => openIdea()}>
                                  {t('SubmittalDetails')}
                              </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                            </div>
                            }
                    </Col>
                    </Row>
                )}


  
  
  OriginalIdeaInfo.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

OriginalIdeaInfo.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default OriginalIdeaInfo;
