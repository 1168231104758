import PropTypes from "prop-types";
import React from "react";
import { Pie } from 'react-chartjs-2';
import ReactLoading from 'react-loading';
import { Col, Row } from "shards-react";

import Parse from 'parse';
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardFooter,
} from "shards-react";
import colors from "../utils/colors";
import userDashboardMetricService from '../services/userDashboardMetricService';


const blueColor = '#3A7BBB'
const orangeColor = '#FD902c'
const greenColor = '#7FA86F'
const goldColor = '#DDB153'



class EmployeeActivityDashboard extends React.Component { 

  constructor(props) {
    super(props)

    this.state = {
      ideas:[],
      numSubmitted: 0,
      numCompleted: 0,
      numInProgress: 0,
      numPendingEval: 0,
      departments: '',
      categories: '',
      ideaByDepartmentData: [],
      progressByCategoryData:[],
      returnsByCategoryData:[],
      actualReturnsByCategoryData:[],
      completedIdeas:[],
      completeIdeas:[],
      ideasInProgress:[],
      ideasPerCats:[],
      ideaResults:[],
      showChart: false,
      showIdeasByDeptChart: false,
      showProgressByCatChart: false,
      showEarningsByCatChart: false,
      exportData:{},
      ideaDataCsv: [],
      myIdeas: [],
      myApprovedIdeas: [],
      myTeamContributions: [],
      myApprovedTeamIdeas: [],
      basicIdeaByDepartment:[],
      orderedDepartments: [],
      ideasByType: []
    }
    
    this.getIdeas = this.getIdeas.bind(this);
    this.getResults = this.getResults.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  componentDidMount() {
    this.getIdeas();
  }

  async getIdeas() {
      try {
          const data = await userDashboardMetricService.getMetrics();

          this.setState({
              numSubmitted: String(data.opportunities || 0),
              numCompleted: String(data.opportunitiesApproved || 0),
              numInProgress: String(data.opportunitiesImplementing || 0),
              numPendingEval: String(data.opportunitiesMyTeam || 0),
              basicIdeaByDepartment: data.opportunitiesByDepartment || [],
              ideasByType: data.opportunitiesByType || [],
          });
      } catch (error) {
          console.error('Error fetching ideas:', error);
      }
  }

  async getMyTeam() {
    const myUser = Parse.User.current()

    const IdeaObject = Parse.Object.extend("User");
    const displayLimit = 1000;
    const query = new Parse.Query(IdeaObject);
    query.limit(displayLimit).doesNotExist("deletedAt").equalTo("department", myUser.get("department"))
    const results = await query.find();
    return results
  }

  async getResults() {
    const IdeaObject = Parse.Object.extend("Result");
    const query = new Parse.Query(IdeaObject);
    const results = await query.find();
    this.setState({ideaResults: results});
  }

  async getCategories() {
    const Category = Parse.Object.extend("IdeaCategory");
    const query = new Parse.Query(Category);
    const results = await query.find();
    this.setState({categories: results}, () => this.setupProgressByCategoryData());
  }

  async getDepartments() {
    this.getCategories();
    // Modify Idea Data
    this.downloadIdeaData()
    const Department = Parse.Object.extend("IdeaDepartment");
    const query = new Parse.Query(Department);
    const results = await query.find();
    this.setState({departments: results}, () => this.setupIdeaByDepartmentData());
  }

  
  getAllDepartmentsFromIdeas() {
    const { ideas } = this.state;
    const departments = new Set(ideas.map(idea => idea.get("department")));
    return Array.from(departments);
  }



  setupIdeaByDepartmentData() {
    const {ideas, categories, completedIdeas, ideasInProgress, departments, numCompleted, numSubmitted, numInProgress} = this.state;
    var inProgressData = [];
    var basicIdeaByDepartment = []
    var orderedDepartments = []

    const innovation = ideas.filter(idea => idea.get("ideaType") == "innovacion");
    const improvement = ideas.filter(idea => idea.get("ideaType") == "mejoras" || idea.get("ideaType") == "improvement");


    const totalIdeas = ideas.length;
    const innovationPercentage = Math.round((innovation.length / totalIdeas) * 100);
    const improvementPercentage = Math.round((improvement.length / totalIdeas) * 100);

    // Adjust problem solving percentage to ensure total is 100%
    const problemSolvingPercentage = 100 - innovationPercentage - improvementPercentage;

    const ideasByType = [innovationPercentage, improvementPercentage, problemSolvingPercentage]

    const departmentsByIdeas = this.getAllDepartmentsFromIdeas();
    let totalPercentage = 0;
    let lastPercentageIndex = departmentsByIdeas.length - 1; // Index of the last element

    for (var i in departmentsByIdeas) {
      const deptName = departmentsByIdeas[i];
      const filteredDataCount = ideasInProgress.filter(idea => idea.get("department") === deptName);
      const realFilteredDataCount = ideas.filter(idea => idea.get("department") === deptName);

      const newNum = filteredDataCount.length;
      const filteredNewNum = realFilteredDataCount.length;

      let percentage = (filteredNewNum / ideas.length) * 100;
      if (i != lastPercentageIndex) {
        percentage = Math.round(percentage);
        totalPercentage += percentage;
      } else {
        // For the last department, adjust the percentage
        percentage = 100 - totalPercentage;
      }

      basicIdeaByDepartment.push(percentage);
      orderedDepartments.push(deptName);
      inProgressData.push([deptName, newNum]);
    }


    var completedData = [];
    var completedIdeas1 = [];
    var colors = [greenColor, blueColor, orangeColor, goldColor, 'red'];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = completedIdeas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      completedData.push([deptName, newNum]);
      completedIdeas1.push({ title: deptName, value: newNum, color: colors[i]});
    }

    var ideasPerCategory = []
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      const newNum = filteredDataCount.length;
      ideasPerCategory.push({ title: categoryName, value: newNum, color: colors[i]});
    }

    var submittedData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      submittedData.push([deptName, newNum])
    }

    const results = [{
      label: 'Completed',
      data: completedData
    },
    {
      label: 'In Progress',
      data: inProgressData
    },
    {
      label: 'Submitted',
      data: submittedData
    }, []];

     
    this.setState({ideasByType: ideasByType, orderedDepartments: orderedDepartments,basicIdeaByDepartment: basicIdeaByDepartment,ideaByDepartmentData: results, exportData: ideas, completeIdeas: completedIdeas1, ideasPerCats: ideasPerCategory, showIdeasByDeptChart: true});
  }

  setupProgressByCategoryData() {

    this.setupEarningsByCategoryData()
    
    const {ideas, categories} = this.state;
     
    var zeroData = [];
    // var completedData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 0)
      const newNum = filteredDataCount.length;
      zeroData.push([categoryName, newNum])
    }

    var twentyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 25)
      const newNum = filteredDataCount.length;
      twentyFiveData.push([categoryName, newNum])
    }

    var fiftyData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 50)
      const newNum = filteredDataCount.length;
      fiftyData.push([categoryName, newNum])
    }

    var seventyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 75)
      const newNum = filteredDataCount.length;
      seventyFiveData.push([categoryName, newNum])
    }

    var hundredData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea =>  idea.get("category") == categoryName && idea.get("progress")[0] == 100)
      const newNum = filteredDataCount.length;
      hundredData.push([categoryName, newNum])
    }

    const results = [{
      label: '0%',
      data: zeroData
    },
    {
      label: '25%',
      data: twentyFiveData
    },
    {
      label: '50%',
      data: fiftyData
    },
    {
      label: '75%',
      data: seventyFiveData
    },
    {
      label: '100%',
      data: hundredData
    }, []];
     

    this.setState({progressByCategoryData: results, showProgressByCatChart: true});
  }

  downloadIdeaData() {
    const modifiedData = this.state.ideas.map((idea) => {
      const newData = {
        "Idea Originator": idea.get("proponentName"),
        "Idea Number": idea.get("num"),
        "Idea Title": idea.get("title"),
        "Description": idea.get("description"),
        "Idea Category": idea.get("category"),
        "Idea Type": idea.get("type"),
        "Business Value": idea.get("questionAnswer"),
        "Supervisor/Owner": idea.get("responsibleName"),
        "Department": idea.get("department"),
        "Submitted": idea.get("date"),
        "Updated": idea.get("customUpdatedAt"),
        "Progress": idea.get("progress"),
        "Implementation Type": idea.get("status"),
        "Edited": idea.get("edited"),
        "Idea Details": idea.get("filterAnswer"),
        "Comments": idea.get("comments"),
        "Has Team": idea.get("hasTeam"),
        "Idea Team": idea.get("teamMembers"),
        "Needs Evaluation": idea.get("needsEvaluation"),
      }

      return(newData)
    })
     
    this.setState({ideaDataCsv: modifiedData})
  }

  setupEarningsByCategoryData() {
    const {ideas, categories} = this.state;
     
    var zeroData = [];
    // var completedData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      var earnings = 0
      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
       earnings = earnings + filteredDataCount[index].get("expectedReturn")
      }
      
      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];
    
     
    this.setState({returnsByCategoryData: results, showChart: true});
  }

    render() {
      const {t} = this.props;
      const { numSubmitted, numCompleted, numInProgress, numPendingEval } = this.state;
      const labels = this.state.basicIdeaByDepartment.map(item => item.label)
      const backgroundColorsByType = ['#2961a7', '#ed7333', '#3f8cf4']
      const backgroundColors = ['#3e8df4', '#2961a8', '#5e41d2', '#ed7333', '#7ec3fa', '#9cd64c', '#5fb2f9', '#8577db', '#6e952f']; // Default colors for the first three categories
      const otherLabels = ['Innovation', 'Continuous Improvement', 'Problem Solving']

      const datasets = [{
        data: this.state.basicIdeaByDepartment.map(item => item.count),
        backgroundColor: backgroundColors
      }]      

      const datasetsByType = [{
        data: this.state.ideasByType.map(item => item.count),
        backgroundColor: backgroundColorsByType
      }]

      var smallStats = [
        {
          label: t('Your Submitted Contributions'),
          value: numSubmitted, //aca poner opportunities
          percentage: "12.4%",
          increase: true,
          chartLabels: [null, null, null, null, null],
          decrease: false,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.primary.toRGBA(0.1),
              borderColor: colors.primary.toRGBA(),
              data: [9, 3, 3, 9, 9]
            }
          ]
        },
        {
          label: t('Your Approved Contributions'),
          value: numCompleted,
          percentage: "7.21%",
          increase: false,
          chartLabels: [null, null, null, null, null],
          decrease: true,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.success.toRGBA(0.1),
              borderColor: colors.success.toRGBA(),
              data: [3.9, 4, 4, 9, 4]
            }
          ]
        },
        {
          label: t("Your Team's Contributions"),
          value: numInProgress,
          percentage: "3.71%",
          increase: true,
          chartLabels: [null, null, null, null, null],
          decrease: false,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.warning.toRGBA(0.1),
              borderColor: colors.warning.toRGBA(),
              data: [6, 6, 9, 3, 3]
            }
          ]
        },
        {
          label: t('Ideas Being Implemented'),
          value: numPendingEval,
          percentage: "3.71%",
          increase: true,
          chartLabels: [null, null, null, null, null],
          decrease: false,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.warning.toRGBA(0.1),
              borderColor: colors.warning.toRGBA(),
              data: [6, 6, 9, 3, 3]
            }
          ]
        },
      ];

     
      

      return (
       <div>

    {/* Small Stats Blocks */}
    
    <Row className="flex"> 
      {/* <Col lg="12" className="m-auto"> */}
      {smallStats.map((stats, idx) => (
            <Col key={idx} md="6" lg="3" className="mb-4">
              <Card className="cardCenter">
                <CardBody>
                  <p>{stats.label}</p>
                  {isNaN(stats.value) ? <ReactLoading type={'spokes'} color={'#633FDA'} width={40} height={40} /> : <p style={{fontSize: 50, fontWeight: 700, color: '#3f8cf4'}}>{stats.value === 0 ? '0' : stats.value}</p>}
                </CardBody>
              </Card>
            </Col>
          ))}
      {/* </Col> */}
    </Row>

    

    <Row>
        <Col lg="12" className="m-auto">
            <Row>
                <Col lg="6" sm="12" className="mb-4">
                    <Card>
                    <CardBody>
                      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                        <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                          {t('Ideas by Department')}
                        </p>
                        <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                          {t("PERCENTAGE")}
                        </p>
                      </div>
                      <Pie data={{labels: labels, datasets: datasets}}  
                        options={{ 
                            maintainAspectRatio: true,
                            responsive: true,
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  label: (tooltipItem) => {
                                      
                                    return  tooltipItem.label + ": " + tooltipItem.formattedValue + "%";
                                  },
                                }
                              },
                              legend: {
                                  display: true,
                                  position: "bottom"
                                },
                            }
                            
                        }} />
                    </CardBody>
                    <CardFooter>
                    {/* <Button>{t('DOWNLOAD_DATA')} &rarr;</Button> */}
                    </CardFooter>
                    </Card>
                </Col>
                <Col lg="6" sm="12" className="mb-4">
                    <Card>
                    <CardBody>
                      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                        <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                          {t('Ideas by Type')}
                        </p>
                        <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                          {t("PERCENTAGE")}
                        </p>
                      </div>
                      <Pie data={{labels: otherLabels, datasets: datasetsByType}}  options={{
                            maintainAspectRatio: true,
                          responsive: true,
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: (tooltipItem) => {
                                  return  tooltipItem.label + ": " + tooltipItem.formattedValue + "%";
                                },
                              }
                            },
                            legend: {
                                display: true,
                                position: "bottom"
                              },
                          }
                      }} />
                    </CardBody>
                    <CardFooter>
                    {/* <Button>{t('DOWNLOAD_DATA')} &rarr;</Button> */}
                    </CardFooter>
                    </Card>
                </Col>
            </Row>
         </Col>
      </Row>
      </div>
      )
    }
  }


EmployeeActivityDashboard.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

EmployeeActivityDashboard.defaultProps = {
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(EmployeeActivityDashboard);
