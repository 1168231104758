import Parse from 'parse';
import React from "react";
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import "./LocalStyles/CategorySelectStyle.css";
import { fetchDepartments } from '../../services/tableService';
const selectedColor = '#157ffb';


class DepartmentSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data:[],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            selectedPerson:this.props.selectedVal,
          }
          this.fetchCategoryData = this.fetchCategoryData.bind(this);
          this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    async fetchCategoryData() {
        try {
            // Fetch departments using the fetchDepartments service
            const departments = await fetchDepartments();  // Assuming fetchDepartments returns an array of department objects
            
            const options = [];
            if (this.props.type === 'Committee') {
                options.push({ "value": 'All Departments', "label": 'All Departments' });
            }

            // Assuming each department object has an 'id' and 'name'
            departments.forEach(department => {
                options.push({
                    value: department.id,
                    label: department.title  // Use appropriate field names based on your service's response
                });
            });

            this.setState({
                data: departments,  // Store fetched departments
                options: options    // Store formatted options for the Select component
            });

        } catch (error) {
            console.error('Error fetching departments:', error);
            this.setState({ data: [], options: [] });
        }
    }

    changeSelectedValue(selectionValue) {
        if (selectionValue === 1) {
            this.setState({
                selectionValue: 'Seguridad', 
                sq1Color: selectedColor,
                sq2Color: '',
                puzzleIcon: 'black',
                gearIcon: selectedColor,
                titleColor1: selectedColor,
                titleColor2: 'black',
            })
            this.props.setFilter('innovacion');
        } else if (selectionValue === 2){
            this.setState({
                selectionValue: 'Calidad', 
                sq1Color: '',
                sq2Color: selectedColor,
                puzzleIcon: selectedColor,
                gearIcon: 'black',
                titleColor1: 'black',
                titleColor2: selectedColor,
            })
             this.props.setFilter('solucion');
        } 
    }

    handleSelectedPersonChange(event) {
        this.props.setResponsible(event, 0);
    }

    getFullName(result) {
        if (result) {
            return result.get("firstName") + ' ' + result.get("lastName");
        }
    }

    render() {
        const { options, coaches } = this.state;
        const { selectedVal, isMurmurattoUser} = this.props;
        const murmurattoObj = isMurmurattoUser && {"value":'Murmuratto', "label":'Murmuratto'};

        return(
           this.props.evalType=="execution"?
                <div  style={{width: '100%'}}>
                    <Select
                        value={isMurmurattoUser ? murmurattoObj : selectedVal}
                        onChange={this.handleSelectedPersonChange}
                        options={isMurmurattoUser ? [] : options}
                        placeholder={'Department'}
                        clearable={false}
                        className="insideFont"
                        disabled={isMurmurattoUser}
                    />
                </div>
            :
                <div  style={{minWidth: 250}}>
                    <Select
                        value={selectedVal}
                        onChange={this.handleSelectedPersonChange}
                        options={coaches}
                        placeholder={'No Coach'}
                        className="insideFont"
                    />
                </div>
        )
    }
}

export default withTranslation()(DepartmentSelect);