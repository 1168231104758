import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Col, Row } from "shards-react";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import colors from "../../utils/colors";
import EditUserForm from '../common/EditUserForm';


function NewUserItem({user, updateUsers, open, canEdit, shouldReset}) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(1)
  const [canGoNext, setCanGoNext] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(open)
  const [selectedUser, setSelectedUser] = useState(user)
  
  const setInitialTitle = async (user) => {
    setTitle('Administration > Categories')

  }

  useEffect(() => {
    // Update the document title using the browser API
    setInitialTitle()
  });
  const setFinished = () => {
    setIdeaStage(3)
  }

  const changeBtnStatus = (status) => {

   setCanGoNext(status)
  }

  const changeContinueBtnStatus = (status) => {

   setCanContinue(status)
  }

  const openUser = () => {
    setSelectedUser('')
    setCategoryIsOpen(true)
  }

  const categoryNameEnglish = "User Name"

  return (
    <Row>
      <Col lg="12" className="m-auto">
              <div style={{width: '100%', borderStyle: 'solid',borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 5, display: 'flex'}}>
                <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
                    <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>{categoryNameEnglish}</h6>
                </div>

                { categoryIsOpen && <ArrowUpIcon onClick={() => setCategoryIsOpen(false)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowUpIcon> }
                { !categoryIsOpen && <ArrowDownIcon onClick={() => openUser()} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowDownIcon>}
              
              </div>
              { categoryIsOpen &&
              <div style={{backgroundColor: '#F6F6F6',}}>
               <EditUserForm canEdit={canEdit} refreshUsers={updateUsers} userData={selectedUser} canSubmit={canSubmit} setFinishedSaving={() => setFinished()} ideaStage={ideaStage} changeStatus={(status) => changeBtnStatus(status)} changeContinueStatus={(status) => changeContinueBtnStatus(status)} changeIdeaStage={() => this.changeIdeaStage()}/>
              </div>
              }
      </Col>
    </Row>
  )}

NewUserItem.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

NewUserItem.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default NewUserItem;
